import React, {Component} from "react";
import {changeTitleType, closePage, log, setTitle, toast} from "../../RubickJSBridge";
import {Col, Row} from "@nutui/nutui-react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import {_baseApiUrl} from "../../Config";


export class TenDataList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        setTitle("数据明细")
        changeTitleType(2)
    }

    componentDidMount() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        this.aid = params['aid'];
        this.getList()
    }

    getList() {
        axios.post(_baseApiUrl + "api/coreData/data/post", {"provider": this.aid})

            .then(response => {
                console.log("resp", response.data.data)
                let data = response.data.data
                this.setState({
                    mData: data
                })

            })
            .catch(function (error) {
                log(error.toString())
                toast("请求失败请重试", "error")
            })
    }


    title() {
        const handleGoBack = () => {
            if (window.history.length > 1) {
                window.history.back(); // 返回上一页
            } else {
                closePage()
            }
        }

        return (<div style={{display: "flex", height: "45pt", paddingLeft: "10vw", alignItems: "center"}}>
            <FontAwesomeIcon icon={faArrowLeft} size={"lg"} onClick={event => {
                handleGoBack()
            }}/>

            <div style={{marginLeft: "10pt", fontSize: "12pt"}}>
                数据明细
            </div>
        </div>)
    }


    item(data) {

        const keys = this.state.mData.keys;
        return (<div
            style={{
                backgroundColor: "#181818",
                borderRadius: "2pt",
                // height:"30vw",
                marginTop: "1pt",
                marginLeft: "5vw",
                marginRight: "5vw",
                paddingLeft: "10pt",
                paddingRight: "10pt",
                paddingTop: "8pt",
                paddingBottom: "4pt"
            }}>
            <div className={"r_gray2"} style={{
                marginBottom:"4pt"
            }}>{data[0]}
            </div>
            <div style={{
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                padding: "2pt"
            }}>
                <Row type="flex" justify="space-between">
                    <Col span="8">
                        <div>
                            <div className={"r_gray2"} style={{textAlign: "center"}}>{keys[1].name}({keys[1].unit})
                            </div>
                            <div className={"r_sub_count"} style={{
                                textAlign: "center",
                                marginTop: "1vw",
                                color: data[1] > data[3] ? "red" : "green"
                            }}>{data[1]}</div>
                        </div>
                    </Col>
                    <Col span="8">
                        <div>
                            <div className={"r_gray2"} style={{textAlign: "center"}}>{keys[2].name}({keys[2].unit})
                            </div>
                            <div className={"r_sub_count"}
                                 style={{textAlign: "center", marginTop: "1vw"}}>{data[2]}</div>
                        </div>
                    </Col>
                    <Col span="8">
                        <div>
                            <div className={"r_gray2"} style={{textAlign: "center"}}>{keys[3].name}({keys[3].unit})
                            </div>
                            <div className={"r_sub_count"}
                                 style={{textAlign: "center", marginTop: "1vw"}}>{data[3]}</div>
                        </div>
                    </Col>

                </Row>
            </div>
        </div>)


    }

    render() {

        let items = <></>
        if (this.state.mData) {
            items = this.state.mData?.values?.map((data) => {
                return this.item(data)
            })
        }
        return (<div>
            {this.title()}
            {items}
        </div>)
    }
}