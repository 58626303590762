import React, {useEffect, useState} from "react";
import {Tabs, Toast, Cell, CellGroup, Row, Col, Dialog, ConfigProvider} from '@nutui/nutui-react';
import axios from "axios";
import {_baseApiUrl} from "../../Config";
import {log, toast} from "../../JSBridge";
import 'echarts/lib/chart/bar'; // 导入柱状图
import 'echarts/lib/component/tooltip'; // 导入提示框组件
import 'echarts/lib/component/title';
import {BarChart} from "../tenData/TenDataCommonV2Component";
import {appInfo, dashNeedRefresh, reloadWidget, userInfo} from "../../RubickJSBridge";

const Zhibiao = () => {


    return (
        <div>
            <ZhibiaoDetail/>
        </div>
    );
}


function formatDate(timestamp) {
// 使用构造函数将时间戳转化为日期对象
    const date = new Date(timestamp); // 注意 JavaScript 中时间戳是以毫秒计数的，因此需要乘以1000
// 使用日期对象的方法获取具体的年、月、日等信息
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // 月份从 0 开始计数，因此需要加1
    const day = date.getDate();
    return `${year}-${month}-${day}`
}

//指标详情
export const ZhibiaoDetail = () => {
    //详情数据
    const [data, setData] = useState({});
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');
    if (!id) {
        return (<div></div>)
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        //请求列表数据
        Toast.show({
            content: '请稍后',
            icon: 'loading',})

        axios.post(_baseApiUrl + "api/coreData/data/post", {
            "provider": "zhibiao",
            "type": "data",
            "id": id
        }).then(function (response) {
            Toast.clear()
            let mData = response.data.data
            setData(mData)
        }).catch(function (error) {
            log(error.toString())
            Toast.clear()
            toast("请求失败请重试", "error")
        })
    }, [])
    //标题
    if (!data.name) {
        return <div></div>
    }
    let chart = (<div></div>);
    let list = (<div></div>);
    if (data.dataDetail) {
        let labels = data.dataDetail.slice(0,30).map((val) => {
            return formatDate(val.timestamp);
        });
        chart = (
            <BarChart
                title={data.name + "(" + data.dataUnit + ")"} color={"#749AA0"} type={"bar"} labels={labels}
                data={data.dataDetail.slice(0,30).map((val) => {
                    return val.dataValue
                })}/>
        );
        list = data.dataDetail.map((val) => {
            return (
                <Row style={{ color: '#eee' }}>
                    <Col
                        span={16}
                        style={{
                            fontSize: '14pt',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {val.dataValue}
                    </Col>
                    <Col
                        span={8}
                        style={{
                            fontSize: '12pt',
                            color: '#aaa',
                            textAlign: 'right',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                        }}
                    >
                        {formatDate(val.timestamp)}
                    </Col>
                </Row>
            )
        });
    }

    return (<div style={{margin: "5vw"}}>
        <div style={{fontSize: "20pt", fontWeight: "bold", color: "#fff"}}>{data.name}</div>
        <div style={{fontSize: "10pt", color: "#ccc", marginTop: "10pt"}}>当前值： <span
            style={{fontSize: "20pt", fontWeight: "bold", color: "#fff"}}>{data.dataValue} <span
            style={{
                fontSize: '8pt',
                backgroundColor: 'rgba(94,175,255,0.21)', // 半透明背景颜色（黑色，透明度50%）
                borderRadius: '5px', // 圆角半径
                padding: '4px 10px', // 内边距（上下2px，左右5px）
                color: '#fff' // 文字颜色（白色）
            }}
        >
  {data.dataUnit}
</span></span></div>
        <div style={{
            fontSize: "10pt",
            color: data.qoq > 0 ? "#ff5e5e" : "#b5ffb5",
            marginTop: "4pt"
        }}>环比: {(data.qoq * 100).toFixed(2)}%
        </div>
        <div style={{
            fontSize: "10pt",
            color: "#ccc",
            marginTop: "4pt"
        }}>更新: {data.periodDate} 发布频率：{data.frequency}</div>

        <div style={{marginTop: "20pt"}}></div>
        {chart}
        <div style={{fontSize: "14pt", fontWeight: "bold", color: "#fff"}}>数据明细 <span
            style={{
                fontSize: '8pt',
                backgroundColor: 'rgba(94,175,255,0.21)', // 半透明背景颜色（黑色，透明度50%）
                borderRadius: '5px', // 圆角半径
                padding: '4px 10px', // 内边距（上下2px，左右5px）
                color: '#fff' // 文字颜色（白色）
            }}
        >
  {data.dataUnit}
</span>

        </div>
        <div style={{marginTop: "8pt"}}>
            {list}
        </div>
        <div style={{height: "14vw", display: "flex", alignItems: "center", justifyContent: "center"}}
             className={"r_gray2"}>
            *数据来源互联网，以实际数据为准
        </div>


    </div>)


}


//指标菜单
export const ZhibiaoMenu = () => {
    const [tabValue, setTabValue] = useState('0');
    const [tabValue1, setTabValue1] = useState('0');
    const [indicator, setIndicator] = useState([]);

    useEffect(() => {
        //请求列表数据
        //获取数据
        Toast.show({
            content: '请稍后',
            icon: 'loading',
        })
        axios.post(_baseApiUrl + "api/coreData/data/post", {
            "provider": "zhibiao",
            "type": "indicator"
        }).then(function (response) {
            Toast.clear()
            let mData = response.data?.data?.data
            setIndicator(mData)
        }).catch(function (error) {
            log(error.toString())
            Toast.clear()
            toast("请求失败请重试", "error")
        })
    }, [])
    //添加数据到仪表盘
    let addBrick = (alias) => {

        Toast.show({
            content: '请稍后',
            icon: 'loading',
        })
        appInfo((appInfo) => {
            userInfo((userInfo) => {
                console.log(appInfo)
                console.log(userInfo)
                axios({
                    method: 'get',
                    url: _baseApiUrl + `api/coreData/userBricks/add`,
                    params: {
                        brickId:0,
                        id:-1,
                        alias: alias
                    },
                    headers:{"x-uid":userInfo.uid,"x-token":userInfo.token,"x-plat":appInfo.platform,"x-ver":appInfo.app_version}
                }).then(function (response) {
                    Toast.clear()
                    if(response.data.code === 0){
                        Toast.show({
                            content: '数据添加成功',
                            icon: 'success',
                        })
                        dashNeedRefresh()
                        reloadWidget()
                    }else{
                        Toast.show({
                            content: response.data.msg,
                            icon: 'fail',
                        })
                    }
                }).catch(function (error) {
                    log(error.toString())
                    Toast.clear()
                    toast("请求失败请重试", "error")
                })
            })
        })


    }

    //第一层,横向导航
    let items = indicator.map((item, index) => {
        //第二层，侧边导航
        let childItems = item["childrenList"].map((item, index) => {
            //第三层，子item
            let innerItems = item["childrenList"].map((item, index) => {
                return (<Cell title={item.name} isLink onClick={e => {
                    //组件别名
                    let alias = `zhibiao#${item.indicId}`
                    Dialog.alert({
                        title: '添加数据到仪表盘',
                        content: item.name,
                        onConfirm: () => {
                            console.log("添加数据到仪表盘:",alias)
                            addBrick(alias)
                        }
                    });
                }
                }/>)
            })
            return (
                <Tabs.TabPane  title={item.name} titleScroll={true} ><CellGroup>{innerItems}</CellGroup></Tabs.TabPane>)
        })
        return (<Tabs.TabPane title={item.name}  >
            <Tabs value={tabValue1} onChange={({paneKey}) => {
                setTabValue1(paneKey)
            }} titleScroll direction="vertical">
                {childItems}
            </Tabs>
        </Tabs.TabPane>)


    })
    return (
        <div style={{width:"100vw",height:"100vh",color: "#fff",
            "--nutui-tabs-tabpane-backgroundColor":"#000000"}}>
            <div style={{margin: "20pt", fontWeight: "bold", fontSize: "20pt"}}>指标库</div>
            <Tabs value={tabValue}  onChange={({paneKey}) => {
                setTabValue(paneKey)
            }}>
                {items}
            </Tabs>
        </div>
    );
};

export default Zhibiao;
