import React from "react";
import "../rubick.css"
import "./Wangyiyun.css"
import {Image} from "@nutui/nutui-react";
import axios from "axios";
import {_baseApiUrl} from "../../Config";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPause, faPlay} from '@fortawesome/free-solid-svg-icons';
import {changeTitleType, eventReport, setTitle, toast} from "../../RubickJSBridge";


export class WangYiYun extends React.Component {


    constructor(props) {
        super(props);
        const searchParams = new URLSearchParams(window.location.search);
        const id = searchParams.get('id');
        this.audioRef = React.createRef();
        this.wakeLock = null;

        setTitle("魔方音乐")
        changeTitleType(1)
        eventReport("pv", "music")

        this.state = {
            id: id,
            cover: "",
            url: "",
            songs: "",
            sings: "",
            name: "",
            comment: "",
            isPlaying: false,
            canPlay: true
        }

        this.getData()


    }


    getData() {
        axios.post(_baseApiUrl + "api/coreData/data/post", {"provider": "wangyireping", "id": this.state.id})

            .then(response => {
                let data = response.data.data
                this.setState({
                    id: data.id,
                    cover: data.cover,
                    url: data.url,
                    songs: data.songs,
                    sings: data.sings,
                    name: data.name,
                    comment: data.comment
                })
            })
            .catch(function (error) {
                toast("请求失败请重试", "error")
            })
    }


    componentDidMount() {
        // 检查浏览器是否支持 wakeLock API
        if ('wakeLock' in navigator) {
            // 请求屏幕常量
            navigator.wakeLock
                .request('screen')
                .then((lock) => {
                    this.wakeLock = lock;
                    console.log('屏幕常量已启用');
                })
                .catch((error) => {
                    console.error('无法启用屏幕常量:', error);
                });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
        if (this.wakeLock) {
            this.wakeLock.release().then(() => {
                console.log('屏幕常量已释放');
            });
        }
    }

    handleVisibilityChange = () => {
        if (document.visibilityState === "hidden") {
            const audio = this.audioRef.current;
            if (this.state.isPlaying) {
                audio.pause(); // 暂停音频
                this.setState({
                    isPlaying: false
                })
            }
        }
    };


    render() {

        if (this.state.url === "") {
            return (<></>)
        }
        // let ref =  useRef()
        let togglePlay = () => {
            console.log("togglePlay")

            const audio = this.audioRef.current;
            if (this.state.isPlaying) {
                audio.pause(); // 暂停音频
            } else {
                audio.play(); // 播放音频
                document.addEventListener("visibilitychange", this.handleVisibilityChange);

                // TODO ios 1.0.3 需要处理
                // appInfo(function (data) {
                //    if(data["platform"]==="20" && parseInt(data["app_build"]) > 1032){
                //        document.removeEventListener("visibilitychange", that.handleVisibilityChange);
                //    }
                // })

            }
            this.setState(prevState => ({
                isPlaying: !prevState.isPlaying // 更新播放状态
            }));
        }
        console.log(this.state.url)

        let handleLoadError = (event) => {
            toast("歌曲加载失败", "error")
        }

        let audioEnd = (event) => {
            this.setState({
                isPlaying: false
            })
        }

        return (
            <div style={{backgroundImage: 'url(' + this.state.cover + ')'}} className="glass-container">
                <div className="glass-overlay"/>

                <div style={{position: "relative", height: "100%", overflowY: "auto", paddingTop: "10vw"}}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "16pt",
                        color: "white"
                    }}>{this.state.songs}</div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "4vw",
                        fontSize: "10pt",
                        color: "white"
                    }}>{this.state.sings}</div>
                    <div style={{
                        width: "100vw",
                        height: "80vw",
                        marginTop: "20pt",
                        position: "relative"
                    }}>
                        <div style={{
                            zIndex: 100000,
                            position: "absolute",
                            left: "40vw",
                            top: "30vw",
                            width: "20vw",
                            height: "20vw",
                            backgroundColor: "#00000033",
                            borderRadius: "50%",
                            borderWidth: "10pt",
                            borderColor: "#ffffff",
                            display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center"
                        }}>
                            <audio
                                ref={this.audioRef}
                                src={this.state.url}
                                onError={handleLoadError}
                                onEnded={audioEnd}
                                onCanPlay={() => {
                                    this.setState({
                                        canPlay: true
                                    })
                                }}

                            >
                            </audio>
                            <div style={{}}>
                                {this.state.canPlay ? (
                                    <FontAwesomeIcon icon={this.state.isPlaying ? faPause : faPlay} size={"2xl"}
                                                     onClick={event => {
                                                         togglePlay()
                                                     }}/>
                                ) : (
                                    <></>
                                )}
                            </div>

                        </div>
                        <div className={"image-container"} style={{
                            zIndex: 1000,
                            position: "absolute",
                            left: "30vw",
                            top: "20vw",
                            width: "40vw",
                            height: "40vw"
                        }}>
                            <Image src={this.state.cover} height={"40vw"} width={"40vw"}
                                   className={`${this.state.isPlaying ? 'rotating' : ''}`} fit="contain" radius="50%"/>
                        </div>
                        <Image src={require("./img/music_bg.png")} style={{
                            width: "80vw",
                            height: "80vw",
                            borderRadius: "50%",
                            position: "absolute",
                            left: "5vw",
                            top: "0",
                            zIndex: 100
                        }}/>
                    </div>

                    <div
                        style={{
                            width: "100vw",
                            marginTop: "10vw",
                            display:"flex",
                            alignItems:"center",
                            justifyContent:'center'
                            }}
                    >
                        <div style={{
                            color: "white",
                            fontSize: "14pt",
                            backgroundColor: "#18181888",
                            borderRadius: "5vw",
                            minHeight: "50vw",
                            width: "68vw",
                            padding: "6vw",
                        }}>
                            {this.state.comment}
                            <div style={{
                                color: "grey",
                                fontSize: "10pt",
                                right: "5vw",
                                bottom: "4vw",
                                position: "absolute"
                            }}>by:{this.state.name}</div>

                        </div>
                        {/*<div style={{color: "white", fontSize: "30pt", top: "-4vw", position: "absolute",left:"5vw"}}>““““</div>*/}

                    </div>
                </div>


            </div>)
    }


}