import React, {Component} from "react";
import {changeTitleType, closePage, eventReport, log, setTitle, toast} from "../../RubickJSBridge";
import {Col, Row} from "@nutui/nutui-react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import {_baseApiUrl} from "../../Config";


export class YoujiaV2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        setTitle("油价信息")
        changeTitleType(1)
        eventReport("pv","youjia")
    }

    componentDidMount() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        this.getList()
    }

    getList() {
        axios.post(_baseApiUrl + "api/coreData/data/post", {"provider": "youjia"})

            .then(response => {
                console.log("resp", response.data.data)
                let data = response.data.data
                this.setState({
                    mData: data
                })

            })
            .catch(function (error) {
                log(error.toString())
                toast("请求失败请重试", "error")
            })
    }


    item(data) {

        return (<div
            style={{
                backgroundColor: "#181818",
                borderRadius: "2pt",
                marginTop: "1pt",
                marginLeft: "10pt",
                marginRight: "10pt",
                padding: "4pt",
                paddingLeft:"20pt"
            }}>
            <div style={{
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
            }}>
                <Row type="flex" justify="space-between"
                     style={{
                         textAlign: "left"
                         , color: "#CC6C00"
                         , fontSize: "13pt"
                         , fontWeight: "bold"
                         , display: "flex"
                         , alignContent: "center"
                         , alignItems: "center",
                         justifyContent: "center",


                     }}
                >
                    <Col span="6">
                        <div >
                            <div >{data.province}</div>
                        </div>
                    </Col>
                    <Col span="5">
                        <div>
                            <div className={"r_gray2"} style={{textAlign: "center"}}>#92</div>
                            <div className={"r_sub_count"}
                                 style={{textAlign: "center", marginTop: "2vw",fontSize:"11pt"}}>{data["92"]}</div>
                        </div>
                    </Col>
                    <Col span="5">
                        <div>
                            <div className={"r_gray2"} style={{textAlign: "center"}}>#95</div>
                            <div className={"r_sub_count"}
                                 style={{textAlign: "center", marginTop: "2vw",fontSize:"11pt"}}>{data["95"]}</div>
                        </div>
                    </Col>
                    <Col span="5">
                        <div>
                            <div className={"r_gray2"} style={{textAlign: "center"}}>#98</div>
                            <div className={"r_sub_count" }
                                 style={{textAlign: "center", marginTop: "2vw",fontSize:"11pt"}}>{data["98"]}</div>
                        </div>
                    </Col>
                    <Col span="5">
                        <div>
                            <div className={"r_gray2"} style={{textAlign: "center"}}>#0</div>
                            <div className={"r_sub_count"}
                                 style={{textAlign: "center", marginTop: "2vw",fontSize:"11pt"}}>{data["0"]}</div>
                        </div>
                    </Col>

                </Row>
            </div>
        </div>)


    }

    bottom() {
        return (<div style={{height:"14vw",display:"flex",alignItems:"center",justifyContent:"center"}} className={"r_gray2"}>
            *数据来源互联网，以实际数据为准
        </div>)
    }
    render() {
        let items = <></>
        if (this.state.mData) {

            const list = []
            for (const key in this.state.mData) {
                if (this.state.mData.hasOwnProperty(key)) {
                    const second = this.state.mData[key]
                    list.push(second)
                }
            }

            items = list.map((data) => {
                return this.item(data)
            })
        }
        return (<div>
            <div className={"r_title2"} style={{marginTop:"20vw",marginLeft:"10vw",marginBottom:"4vw"}}>全国油价</div>

            {items}
            {this.bottom()}
        </div>)
    }
}