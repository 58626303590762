import React, { useState } from 'react';
import {  Input, Button } from "@nutui/nutui-react";
import {Slider} from "primereact/slider";
import * as echarts from 'echarts';

const WaterTracker = () => {
    const [dailyGoal, setDailyGoal] = useState(8); // 默认每天喝水目标为8杯
    const [waterAmount, setWaterAmount] = useState(0); // 当前喝水总量
    const [cupSize, setCupSize] = useState(250); // 默认杯子容量为250毫升
    const [waterRate, setWaterRate] = useState(0); // 喝水评率
    const [waterRecords, setWaterRecords] = useState([]); // 喝水记录

    const handleWaterRecord = (amount) => {
        const currentWaterAmount = waterAmount + amount;
        const currentWaterRate = (currentWaterAmount / (dailyGoal * cupSize)) * 100;
        const record = {
            amount,
            time: new Date().toLocaleString()
        };

        setWaterAmount(currentWaterAmount);
        setWaterRate(currentWaterRate);
        setWaterRecords([...waterRecords, record]);
    };

    const handleGoalChange = (value) => {
        setDailyGoal(Number(value));
    };

    const handleCupSizeChange = (value) => {
        setCupSize(Number(value));
    };

    const getOption = () => {
        // ECharts的配置项，用于绘制图表
        return {
            tooltip: {
                trigger: 'item',
                formatter: '{b}: {c}ml'
            },
            series: [
                {
                    type: 'liquidFill',
                    data: [waterRate / 100],
                    radius: '80%',
                    itemStyle: {
                        color: '#37A2DA',
                        opacity: 0.6
                    },
                    label: {
                        show: true,
                        formatter: function (params) {
                            return (params.value * 100).toFixed(2) + '%';
                        },
                        fontSize: 14,
                        fontWeight: 'bold',
                        color: '#333'
                    }
                }
            ]
        };
    };

    return (
        <div className="water-tracker">
            <div className="title">Water Tracker</div>
            <div className="settings">
                <div className="setting">
                    <div className="label">Daily Goal:</div>
                    <Input className="input" type="number" value={dailyGoal} onChange={handleGoalChange} />
                </div>
                <div className="setting">
                    <div className="label">Cup Size:</div>
                    <Input className="input" type="number" value={cupSize} onChange={handleCupSizeChange} />
                </div>
            </div>
            <div className="statistics">
                <div className="stat-label">Daily Water Amount: {waterAmount} ml</div>
                <div className="chart">
                    {/*<ECharts option={getOption()} echarts={echarts} />*/}
                </div>
                <div className="stat-label">Water Drinking Rate: {(waterRate).toFixed(2)}%</div>
            </div>
            <div className="records">
                <div className="records-title">Water Records</div>
                {waterRecords.map((record, index) => (
                    <div className="record" key={index}>
                        <div className="record-info">Amount: {record.amount} ml</div>
                        <div className="record-info">Time: {record.time}</div>
                    </div>
                ))}
            </div>
            <div className="record-water">
                <div className="record-title">Record Water Intake</div>
                <div className="cup-size">Current Cup Size: {cupSize} ml</div>
                <Button className="record-btn" onClick={() => handleWaterRecord(cupSize)}>Drink One Cup</Button>
                <div className="slide-label">Slide to Record:</div>
                <Slider
                    value={cupSize}
                    min={0}
                    max={cupSize}
                    step={10}
                    onChange={(value) => handleWaterRecord(Number(value))}
                />
            </div>
        </div>
    );
};

export default WaterTracker;
