import React from 'react';
import '../../JSBridge'
import './VipCenter.css'
import '@nutui/nutui-react/dist/style.css'
import {Dialog, Popup, Toast} from '@nutui/nutui-react';
import {adVideo, log, marketFeedback, openScheme, setTitle, share, toast, userInfo} from "../../JSBridge";
import { IconFont } from '@nutui/icons-react'

import hbg from "./img/bb_bg.jpg"
import coupon_lj from "./img/coupon_lj.png"
import coupon_zk from "./img/coupon_zk.png"
import axios from "axios";
import {_baseApiUrl, _baseWebUrl} from "../../Config";

export class VipCenter extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            aInfo: null,
            error: false,
            showBottom: false,
            duihuanAmount: 0,
        };
        this.updateInfo()
    }


    updateState(error, aInfo) {
        this.setState({
            error: error,
            aInfo: aInfo,
        })
    }


    baseUrl = _baseApiUrl
    mUserInfo = null

    /**
     * 兑换
     * @param type
     * @param amount
     * @param success
     */
    exchange(type, id, amount, success) {
        Toast.show({
            content: '请稍后',
            icon: 'loading',
        })
        axios({
            url: this.baseUrl + "/api/activity/exchange",
            method: "POST",
            headers: {
                token: this.mUserInfo.token
            },
            params: {
                vin: this.mUserInfo.vin,
                phone: this.mUserInfo.phone,
                type: type,
                amount: amount,
                id: id,
            },
            timeout: 5000
        }).then(function (response) {
            Toast.clear()
            console.log(response);
            let data = response.data
            if (data.code !== 0) {
                toast(data.msg, "error")
            } else {
                success()
            }


        }).catch(function (error) {
            log(error.toString())
            Toast.clear()
            toast("请求失败请重试", "error")
        })

    }

    makeMoney(type, amount, success) {
        Toast.show({
            content: '请稍后',
            icon: 'loading',
        })
        axios({
            url: this.baseUrl + "/api/activity/makeMoney",
            method: "POST",
            headers: {
                token: this.mUserInfo.token
            },
            params: {
                vin: this.mUserInfo.vin,
                phone: this.mUserInfo.phone,
                type: type,
                amount: amount,
            },
            timeout: 5000
        }).then(function (response) {
            Toast.clear()
            console.log(response);
            let data = response.data
            if (data.code !== 0) {
                toast(data.msg, "error")
            } else {
                success()
            }


        }).catch(function (error) {
            log(error.toString())
            Toast.clear()
            toast("请求失败请重试", "error")
        })

    }

    /**
     * 获得活动信息
     * @param token
     * @param vin
     * @param phone
     */
    loadActivityInfo() {
        const that = this;
        axios({
            url: this.baseUrl + "/api/activity/info",
            method: "GET",
            headers: {
                token: this.mUserInfo.token
            },
            params: {
                vin: this.mUserInfo.vin,
                phone: this.mUserInfo.phone
            },
            timeout: 5000
        }).then(function (response) {
            console.log(response);
            let data = response.data
            that.updateState(data.code !== 0, data.data)
            Toast.clear()
            if (data.code !== 0) {
                Toast.show({
                    content: data.msg,
                    icon: 'fail',
                })
            }


        }).catch(function (error) {
            that.updateState(true, null)
            Toast.clear()
            Toast.show({
                content: '数据加载失败，请退出重试',
                icon: 'fail',
            })


        })

    }


    updateInfo() {
        userInfo((data) => {
            if (data["code"] === 0 && data["data"] != null) {
                let mData = data.data
                let token = mData.token
                if (token != null && token !== "") {
                    this.mUserInfo = mData
                    this.loadActivityInfo()
                } else {
                    Toast.show({
                        content: '登录信息有误，请重试',
                        icon: 'fail',
                    })
                    this.updateState(true, null)
                }
            }

        })


    }

    header() {

        const info = this.state.aInfo;
        return (
            <div style={{
                height: "48vw",
            }}>
                <img src={hbg} style={{
                    width: "100vw",
                    left: 0,
                    position: "absolute",
                }}/>

                <div style={{
                    position: "absolute",
                    color: "#e9e9e9",
                    top: "5vw",
                    left: "9vw",
                    fontSize: "3.33vw"
                }}>我的B币
                </div>
                <a href={"https://www.widgetc.cn/bmw/api/paper/111"}>
                    <IconFont name="ask2" size="3.33vw" style={{
                    position: "absolute",
                    color: "#e9e9e9",
                    top: "5.8vw",
                    left: "23.35vw",
                }}/>
                </a>

                <div style={{
                    position: "absolute",
                    color: "#E9E9E9",
                    top: "10vw",
                    left: "24vw",
                    fontSize: "15.28vw",

                }}>{info.amount}
                </div>
                <div style={{
                    position: "absolute",
                    color: "#5D2500",
                    top: "5vw",
                    right: "7vw",
                    fontSize: "3.33vw",
                }} onClick={event =>
                    openScheme(_baseWebUrl + "/my_records")
                }>查看明细
                </div>
                <div style={{
                    backgroundColor: "#191919",
                    borderRadius: "15vw",
                    textAlign: "center",
                    position: "absolute",
                    width: "30vw",
                    lineHeight: "8.5vw",
                    height: "8.5vw",
                    top: "27vw",
                    right: "7vw",
                    fontSize: "3vw",
                    border: 0,
                    color: "rgba(255,255,255,0.70)",
                }} onClick={
                    function () {
                        share({
                            "type": "webpage",
                            "pageUrl": info.shareUrl,
                            "title": "超好用的BMW车辆控制软件",
                            "description": "邀请码:" + info.code,
                        })
                    }
                }>
                    邀请好友赚币
                </div>
                <div style={{
                    position: "absolute",
                    width: "30vw",
                    top: "36.3vw",
                    right: "7vw",
                    fontSize: "2.8vw",
                    color: "#191919",
                    border: 0,
                    textAlign: "center",
                }}>
                    邀请码：{info.code}
                </div>

            </div>
        )
    }

    //全额返
    crashBack() {
        const info = this.state.aInfo;
        let that = this

        return (

            <div style={{
                backgroundColor: "rgba(168,127,98,0.5)",
                height: "8.3vw",
                width: "100%",
                marginTop: "2vw",
                lineHeight: "8.3vw",
            }}>
                <span style={{
                    verticalAlign: "middle",
                    marginLeft: "3vw",
                    color: "#FFB98B",
                    backgroundColor: "rgba(25,25,25,0.9)",
                    borderRadius: "1vw",
                    paddingLeft: "1.62vw",
                    paddingRight: "1.62vw",
                    paddingTop: "0.83vw",
                    paddingBottom: "0.83vw",
                    fontSize: "2.22vw",
                }}>会员费全返</span>
                <span style={{
                    verticalAlign: "middle",
                    marginLeft: "2vw",
                    color: "#FFB98B",
                    fontSize: "2.6vw",
                }}>当前已经返还{info.cashback.backAmount / 100}元，还可返{(info.cashback.payAmount - info.cashback.backAmount) / 100}元</span>
                <span style={{
                    position: "absolute",
                    right: "4vw",
                    color: "#FFB98B",
                    fontSize: "3vw",
                }} onClick={event => {
                    that.setState({
                        duihuanAmount: this.state.aInfo.amount,
                        showBottom: true,
                    })
                }}
                >立即兑换</span>
            </div>
        )

    }

    missions() {
        let that = this
        const validate = () => {
            this.setState(this.state)
            this.updateInfo()

        }
        const info = this.state.aInfo;
        const itemClick = function (miss) {
            if (localStorage[miss.key] === "1") {
                return
            }
            switch (miss.title) {
                case "每天看视频签到":
                    // localStorage[miss.key] = "1"
                    adVideo("948325067", "extra", "mark_day",
                        function (data) {
                            Toast.show(JSON.stringify(data))
                            if (data["code"] !== 0) {
                                Toast.show({
                                    content: data["msg"],
                                    icon: 'fail',
                                })
                            } else {
                                that.makeMoney("check-in", 20, function () {
                                    //领取奖励
                                    toast("奖励领取成功")
                                    localStorage[miss.key] = "1"
                                    validate()
                                })
                            }
                        })
                    validate()
                    break;
                case "应用市场5星好评":
                    marketFeedback(function (data) {

                        switch (data.data["result"]) {
                            case "good":
                                toast("感谢您的好评")
                                //request money
                                that.makeMoney("market-feedback", 80, function () {
                                    //领取奖励
                                    toast("奖励领取成功")
                                    localStorage[miss.key] = "1"
                                    validate()
                                })
                                break
                            case "bad":
                                toast("感谢您的反馈")
                                break
                            case "cancel":
                                toast("评价已经取消")
                                break

                        }
                    });
                    break;
                case "邀请好友赚推币":
                    share({
                        "type": "webpage",
                        "pageUrl": info.shareUrl,
                        "title": "超好用的BMW车辆控制软件",
                        "description": "邀请码:" + info.code,
                    })
                    break;

            }

        }
        // const numbers = [{title:"每天看视频签到",amount:"20币",key:"ad_video"+new Date().getFullYear() +"_"+new Date().getMonth() + " " +new Date().getDate()}, {title:"应用市场5星好评",amount:"80币",key:"market_feedback"}, {title:"邀请好友赚推币",amount:"好友实际支付金额(元)*5",key :"friend_invalid"+Date.now()}];
        const numbers = [{title: "应用市场5星好评", amount: "80币", key: "market_feedback"}, {
            title: "邀请好友赚推币",
            amount: "好友实际支付金额(元)*5",
            key: "friend_invalid" + Date.now()
        }];

        const mItems = numbers.map((miss) =>


            <div style={{
                fontSize: "3.33vw",
                background: "#272727",
                lineHeight: "14vw",
                height: "14vw",
                color: "#D9D9D9",
            }}
                 onClick={event => {
                     itemClick(miss)
                 }}
            >
                <span style={{marginLeft: "4vw", verticalAlign: "center", alignContent: "center"}}>{miss.title}</span>
                <span style={{color: "#AA8265", marginLeft: "2vw"}}>{miss.amount}</span>
                <span style={{
                    color: "#AA8265",
                    position: "absolute",
                    right: "4vw"
                }}>{localStorage[miss.key] === "1" ? "已完成" : "去完成"}</span>
            </div>
        )
        return (
            <div>
                <div style={{
                    fontSize: "3.89vw",
                    marginLeft: "3vw",
                    marginTop: "4vw",
                    fontFamily: "PingFangSC, PingFangSC-Light",
                    fontWeight: "300",
                    textAlign: "left",
                    color: "#ffb98b",
                }}>做任务赚B币
                </div>
                <div style={{marginTop: "4vw"}}/>
                <div>{mItems}</div>
            </div>)
    }

    coupons() {
        let that = this;
        const couponList = this.state.aInfo.couponList
        const itemClick = (id) => {
            Dialog.alert({
                title: '优惠券兑换',
                content: '使用B币兑换优惠券',
                onConfirm: e => {
                    this.exchange("coupon", id, 0, function () {
                        toast("优惠券兑换成功")
                        that.updateInfo()
                    })
                }
            });


        }
        const mItems = couponList.map((coupon) =>

            <div style={{
                fontSize: "3.5vw",
                background: "#272727",
                lineHeight: "14vw",
                height: "27.5vw",
                color: "#D9D9D9",
            }}>
                <div style={{
                    width: "92.31vw",
                    height: "24.17vw",
                    paddingTop: "3.33vw",
                }}>
                    <img style={{
                        position: "absolute",
                        marginLeft: "3vw",
                        height: "24.17vw",
                    }} src={coupon.type === "lijian" ? coupon_lj : coupon_zk}/>
                    <div style={{
                        position: "absolute",
                        width: "63.36vw",
                        height: "24.17vw",
                    }}
                    >
                        <div style={{
                            marginTop: "-2vw",
                            position: "relative",
                            width: "63.36vw",
                            height: "4.7vw",
                            fontSize: "3.33vw",
                            color: "#191919",
                            textAlign: "center",
                        }}>{coupon.desc}</div>
                        <div style={{
                            width: "63.36vw",
                            height: "8.33vw",
                            fontSize: "6.11vw",
                            color: "#fff",
                            marginTop: "4vw",
                            textAlign: "center",
                        }}>{coupon.type === "lijian" ? coupon.amount / 100 : coupon.amount / 10}<font
                            style={{fontSize: "3.4vw", paddingLeft: "0.4vw"}}>
                            {coupon.type === "lijian" ? "元" : "折"}
                        </font></div>
                        <div style={{
                            color: "rgba(25,25,25,0.67)",
                            width: "63.36vw",
                            height: "4.72vw",
                            marginTop: "-0.4vw",
                            fontSize: "3.33vw",
                            textAlign: "center",
                        }}>{new Date().toLocaleDateString()}-
                            {coupon.expAppend > 0 ? (new Date(Date.now() + coupon.expAppend).toLocaleDateString()) : coupon.expAt}
                        </div>


                    </div>
                    <div
                        style={{
                            position: "absolute",
                            right: "0",
                            height: "24.17vw",
                            width: "34.98vw",
                        }}
                    >
                        <div style={{
                            width: "34.98vw",
                            height: "8.33vw",
                            fontSize: "4.44vw",
                            color: "rgba(255,255,255,0.70)",
                            marginTop: "2vw",
                            textAlign: "center",
                        }}>{coupon.price}币
                        </div>

                        <div style={{
                            margin: "4.13vw auto",
                            backgroundColor: "#191919",
                            borderRadius: "4.17vw",
                            width: "18vw",
                            height: "5.56vw",
                            lineHeight: "5.56vw",
                            fontSize: "2.78vw",
                            color: "rgba(255,255,255,0.70)",
                            textAlign: "center",
                        }} onClick={event => {
                            itemClick(coupon.cid)
                        }}>立即兑换
                        </div>
                    </div>
                </div>
            </div>
        )
        return (
            <div>
                <div style={{
                    fontSize: "3.9vw",
                    marginLeft: "3vw",
                    marginTop: "4vw",
                    fontFamily: "PingFangSC, PingFangSC-Light",
                    fontWeight: "300",
                    textAlign: "left",
                    color: "#ffb98b",
                }}>会员优惠券兑换
                    <span
                        style={{color: "#fff", fontSize: "3vw", position: "absolute", right: "4vw", marginTop: "1vw",}}
                        onClick={event =>
                            openScheme(_baseWebUrl + "/my_coupons")
                        }>我的优惠券</span>
                </div>
                <div style={{marginTop: "4vw"}}/>
                <div style={{paddingBottom: "3vw", background: "#272727"}}>{mItems}</div>
                <Popup visible={that.state.showBottom} style={{height: '30%', background: "#191919"}} position="bottom"
                       round onClose={() => {
                    that.setState({showBottom: false})
                }}>
                    <div>
                        <div style={{
                            color: "#A87F62",
                            marginLeft: "4vw",
                            marginTop: "4vw",
                            fontSize: "4vw",

                        }}>B币返还会员费 <font style={{fontSize: "3vw", color: "#ddd"}}> ( 100币=1元 )</font></div>
                        <div style={{
                            color: "#fff",
                            fontSize: "10vw",
                            textAlign: "center",
                            marginTop: "8vw",
                        }}><span style={{fontSize: "10vw", marginRight: "4vw"}} onClick={event => {
                            that.setState({
                                duihuanAmount: Math.max(that.state.duihuanAmount - 100, 0)
                            })
                        }}>-</span>{this.state.duihuanAmount}<span style={{fontSize: "10vw", marginLeft: "4vw"}}
                                                                   onClick={event => {
                                                                       that.setState({

                                                                           duihuanAmount: Math.min(that.state.duihuanAmount + 100, that.state.aInfo.amount, that.state.aInfo.cashback.payAmount - that.state.aInfo.cashback.backAmount)
                                                                       })
                                                                   }}>+</span></div>
                        <div style={{
                            marginTop: "8vw",
                            marginLeft: "4vw",
                            marginRight: "4vw",
                            width: "92vw",
                            height: "10vw",
                            borderRadius: "5vw",
                            background: "#A87F62",
                            textAlign: "center",
                            lineHeight: "10vw",
                            color: "#fff",
                        }}
                             onClick={e => {
                                 if (this.state.duihuanAmount < 1000) {
                                     toast("每次兑换金额需大于1000币")
                                     return
                                 }
                                 this.exchange("cash_back", 1, this.state.duihuanAmount, function () {
                                     toast("会员费返还已申请,审核后三个工作日退回到支付账户")
                                     that.setState({
                                         showBottom: false
                                     })
                                     that.updateInfo()
                                 })
                             }}
                        >兑换返现金
                        </div>


                    </div>

                </Popup>

            </div>)
    }

    goods() {
        return (
            <div>
                <div style={{
                    marginTop: "3vw",
                    background: "#191919",
                    height: "21.39vw",
                    lineHeight: "21.39vw",
                    textAlign: "center",
                    fontSize: "3.33vw",
                    color: "#9b9b9b",
                }}>
                    更多兑换敬请期待
                </div>
            </div>
        )
    }


    render() {
        setTitle("B币中心")
        if (this.state.error === true) {
            return (
                <div style={{
                    height: "100%",
                    color: "#fff",
                    fontSize: "4vw",
                    textAlign: "center",
                    marginTop: "30vh"
                }}>
                    发生异常 请退出重试
                </div>);
        } else {
            if (this.state.aInfo == null) {
                return (
                    <div style={{
                        height: "100%",
                        color: "#fff",
                        fontSize: "4vw",
                        textAlign: "center",
                        marginTop: "30vh"
                    }}>
                    </div>);
            } else {
                return (
                    <div style={{background: "#191919"}}>
                        {this.header()}
                        {this.crashBack()}
                        {this.missions()}
                        {this.coupons()}
                        {this.goods()}
                    </div>);
            }

        }

    }

    showDuihuanDialog() {


    }
}
