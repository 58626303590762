import React from "react";

//theme
//core
import "../rubick.css"
import {Col, Image, Row} from "@nutui/nutui-react";

import axios from "axios";
import {changeTitleType, eventReport, log, toast} from "../../RubickJSBridge";
import {_baseApiUrl} from "../../Config";

import 'echarts/lib/chart/bar'; // 导入柱状图
import 'echarts/lib/component/tooltip'; // 导入提示框组件
import 'echarts/lib/component/title';


export class Shuzibi extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        changeTitleType(1)
        eventReport("pv", "shuzibi")

    }

    componentDidMount() {
        this.getList()
    }

    getList() {
        axios.post(_baseApiUrl + "api/coreData/data/post", {"provider": "btc"})

            .then(response => {
                console.log("resp", response.data.data)
                let data = response.data.data
                this.setState({
                    mData: data
                })


            })
            .catch(function (error) {
                log(error.toString())
                toast("请求失败请重试", "error")
            })
    }


    bottom() {
        return (<div style={{height: "14vw", display: "flex", alignItems: "center", justifyContent: "center"}}
                     className={"r_gray2"}>
            *数据来源互联网，以实际信息为准，不做投资参考
        </div>)
    }


    formatDate(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}/${month}/${day}`;
    }

    getColorByRate(rate) {
        const value = parseFloat(rate); // 将字符串转换为数值
        if (value > 0) {
            return '#DC6B66'; // 正数为红色
        } else if (value < 0) {
            return '#8DC0A9'; // 负数为绿色
        } else {
            return '#ffffff'; // 零为黑色（或其他默认颜色）
        }
    }

    formatPrice(price) {
        const priceWithoutSymbol = price.replace(/[￥$,]/g, ''); // 去除人民币符号 "￥" 和美元符号 "$"
        const numericValue = parseFloat(priceWithoutSymbol); // 将去除符号后的字符串转换为数值
        const formattedPrice = numericValue.toFixed(2); // 保留两位小数
        return formattedPrice;
    }

    item(data) {


        const img = data.url.startsWith("http") ? (<Image src={data.url}
                                                          width={"8vw"}
                                                          height={"8vw"}
                                                          fit="contain" radius="50%"
        ></Image>) : (<div style={{
            width: "8vw",
            height: "8vw",

        }}></div>)
        return (<div>
            <div

                style={{
                    height: "12vw",
                    marginTop: "3vw"
                }}
            >
                <Row>
                    <Col span="12">
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div className={"r_gray2"}>{data.index + 1}</div>
                            <div style={{marginLeft: "4vw"}}>
                                {img}
                            </div>

                            <div style={{color: "#ffffff", fontSize: "12pt", marginLeft: "3vw"}}>
                                <div>{data.name}</div>
                            </div>
                        </div>
                    </Col>
                    <Col span="4">
                        <div style={{
                            color: this.getColorByRate(data.rate)
                            , fontSize: "10pt"
                            , display: "flex"
                            , alignItems: "center"
                            , justifyContent: "center",
                            height: "12vw"
                        }}>
                            {data.rate}
                        </div>
                    </Col>
                    <Col span="8">
                        <div style={{
                            display: "flex",
                            flexDirection: "column",/* 垂直排列子元素 */
                            alignItems: "flex-end",
                            justifyContent: "end",
                            alignContent: "end",
                        }}>
                            <div style={{
                                color: "#00ffff",
                                fontSize: "12pt", marginLeft: "2vw",
                                alignItems: "center", justifyContent: "end", alignContent: "end",
                            }}>
                                {data.priceRMB}


                            </div>
                            <div
                                style={{
                                    marginTop: "2vw",
                                    fontSize: "10pt",
                                    color: "#666666",
                                    alignContent: "end"
                                }}>{data.priceUSA}</div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div style={{backgroundColor: "#111111", height: "0.3px", width: "80vw"}}></div>
        </div>)
    }

    items() {
        const list = [];
        for (const [index, value] of this.state.mData.sorts.entries()) {
            const second = this.state.mData[value]
            if (second !== undefined) {
                second.index = index
                list.push(second)
            }
        }
        console.log("list", list)
        const items = list.map((data, index) => {
            return <div key={index}>{this.item(data)}</div>
        })
        return (
            <div>
                <div className={"r_subTitle"} style={{marginBottom: "10vw"}}>今日行情</div>
                {items}
            </div>)
    }


    render() {

        if (this.state.mData === undefined) {
            return <></>
        }

        return (
            <div className={"r_page"}>
                {this.items()}
                {this.bottom()}

            </div>
        )
    }


}
