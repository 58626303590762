import React from "react";
import './rubick.css'
import {Button} from "@nutui/nutui-react";
import {changeTitleType, eventReport, openScheme, setTitle} from "../RubickJSBridge";
import {_baseWebUrl} from "../Config";

export class CommonHelper extends React.Component {

    constructor() {
        super();
        eventReport("pv","addWidget")
        changeTitleType(1)
        setTitle("使用教程")
    }

    render() {


        return (<div className={"r_page"}>

            <div className={"r_title2"}>使用教程</div>
            <div className={"r_content"} style={{marginTop: "6pt"}}>
                1.如何添加小组件
            </div>
            <div className={"r_content"} style={{marginTop: "2pt",color:"#cccccc",fontSize:"12pt",padding:"10pt"}}>
                1.在发现页添加「数据模块」到仪表盘<br/>
                2.回到系统桌面长按空白处进入桌面编辑模式<br/>
                3.点击左上角➕按钮唤起小组件面板<br/>
                4.搜索「魔方信息」添加对应大小的组件<br/>
                5.点按小组件（非编辑模式需长按）选取已经添加到仪表盘的数据模块<br/>
            </div>
            <div>
            <Button color="blue" plain style={{marginTop:"2pt"}} size={"small"} onClick={e => {
                openScheme(_baseWebUrl+"/core_data/addWidget?__titleType=1")
            }}>
                查看视频教程</Button></div>
            <div className={"r_content"} style={{marginTop: "6pt"}}>
                2.想要的数据没有怎么办
            </div>
            <div className={"r_content"} style={{marginTop: "2pt",color:"#cccccc",fontSize:"12pt",padding:"10pt"}}>
                我们数据会陆续丰富，或者您也可以在「数据心愿」中反馈您的数据需求，我们会根据需求热度进行优先添加

            </div>
            <div>
                <Button color="blue" plain style={{marginTop:"2pt"}} size={"small"} onClick={e => {
                    openScheme("coredata:///core/want")
                }}>
                    打开数据心愿</Button></div>

            <div className={"r_content"} style={{marginTop: "6pt"}}>
                3.桌面小组件更新不及时怎么办？
            </div>
            <div className={"r_content"} style={{marginTop: "2pt",color:"#cccccc",fontSize:"12pt",padding:"10pt"}}>
                系统为了省电控制了小组件更新频率，预计更新周期30分钟，如果您需要实时了解信息可以点击小组件到详情页进行查看
            </div>
            <div className={"r_content"} style={{marginTop: "6pt"}}>
                4.如何调整仪表盘数据的顺序
            </div>
            <div className={"r_content"} style={{marginTop: "2pt",color:"#cccccc",fontSize:"12pt",padding:"10pt"}}>
                您可以在仪表盘页面点击右上角编辑图标进入编辑页面，长按移动数据进行调整，或者左滑删除；修改完成后点击右上角保存按钮。
            </div>



            <div style={{height:"30pt",display:"flex",alignContent:"center",alignItems:"center",justifyContent:"center"}} className={"r_gray2"}>
                数据持续丰富中
            </div>

        </div>)
    }
}