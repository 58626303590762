import React from "react";
import {log, setTitle, toast, userInfo} from "../../JSBridge";
import {Toast} from "@nutui/nutui-react";
import axios from "axios";
import {_baseApiUrl} from "../../Config";

export class AccountRecords extends React.Component {


    pageSize = 180
    pageIndex = 0;
    mUserInfo = null;

    constructor(props) {
        super(props);
        this.state = {
            mList: []
        }
        setTitle("账户明细")
        this.updateInfo();
    }

    getList(index, size) {
        let that = this
        Toast.show({
            content: "请稍后",
            icon: 'loading',
        })
        axios({
            url: _baseApiUrl + "/api/activity/accountRecords",
            method: "POST",
            headers: {
                token: this.mUserInfo.token
            },
            params: {
                vin: this.mUserInfo.vin,
                phone: this.mUserInfo.phone,
                pageNum: index,
                pageSize: size,
                orderByColumn:"createTime",
                isAsc:"DESC",
            },
            timeout: 5000
        }).then(function (response) {
            Toast.clear()
            // console.log(response);
            let data = response.data
            if (data.code !== 0) {
                toast(data.msg, "error")
            } else {

                log(data.rows.toString())
                that.pageIndex = index
                that.setState({
                    mList: data.rows

                })
            }


        }).catch(function (error) {
            log(error.toString())
            Toast.clear()
            toast("请求失败请重试", "error")
        })

    }

    updateInfo() {
        let that = this;
        userInfo((data) => {
            if (data["code"] === 0 && data["data"] != null) {
                let mData = data.data
                let token = mData.token
                if (token != null && token !== "") {
                    this.mUserInfo = mData
                    this.getList(that.pageIndex +1, that.pageSize)
                } else {
                    Toast.show({
                        content: "登录信息有误，请重试",
                        icon: 'fail',
                    })
                }
            }

        })


    }

    render() {
        if( this.state.mList == null || this.state.mList.length===0){
            return (<div style={{background: "#191919",textAlign:"center",marginTop:"10vw", color:"#fff",
                fontSize:"3.33vw",}}>暂无数据</div>)
        }
        const content = this.state.mList.map((item) =>

            <div style={{
                height: "11.67vw",
                lineHeight: "11.67vw",
                width: "92vw",
                color:"#fff",
                fontSize:"3.33vw",
                marginLeft:"4vw",
                marginRight:"4vw",
            }}>

                <div>
                    <div style={{position:"absolute"}}>{item.desc}</div>
                    <div style={{right:"4vw",position:"absolute",color:item.amount>0?"#A87F62":"#568F58"}}>{item.amount}</div>
                </div>
                <div style={{
                    background: "#656565",
                    height: "0.1vw",
                    width: "92vw",
                    position:"absolute",
                    marginTop:"11.67vw"
                }}/>
            </div>
        )
        return (<div style={{background: "#191919"}}>{content}</div>)

    }


}