import React from "react";
import "../rubick.css"

import {
    changeTitleType, closePage,
    dashNeedRefresh, eventReport,
    getLocalData, getLocalDataAll, log,
    reloadWidget,
    setLocalData,
    toast
} from "../../RubickJSBridge";
import { Button } from '@nutui/nutui-react';
import { TextArea } from '@nutui/nutui-react';




/**
 * 便签
 * @returns {JSX.Element}
 * @constructor
 */
export class Bianqian extends React.Component {

    colors = ["#181818","#23B2D2","#C22627","#CC6C00"]
    constructor(props) {
        super(props);

       this.state = {
           content: "22",
           time: 0,
           bgColor: "#181818"
       }


    }

    render() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        // 从 URL 中获取特定参数的值
        const pid = params['__pid']
        // const pid = '14268_14127_1721289197720'

        let that = this
        let bgColors = this.colors.map(function (value) {

            let select = value === that.state.bgColor ? (<div style={{
                backgroundColor:"#ffffff",
                borderRadius:"50%",
                width:"10pt",
                height:"10pt",
            }}/>) : (<div/>);
            return (<div style={{
                backgroundColor:value,
                borderRadius:"50%",
                border: "1px solid white",
                width:"24pt",
                height:"24pt",
                marginLeft:"10pt",
                display:"flex",
                alignItems:"center",
                justifyContent: "center" /* 在水平方向上居中对齐（可选）*/

            }} onClick={event => {
                that.setState({
                    bgColor:value
                })
            }
            }>
                {select}
            </div>)
        })

        return (<div class={"r_page"}>
            <div class={"r_title"}>便签</div>
            <div style={{height:"10vw"}}></div>
            <TextArea

                value={this.state.content}
                onChange={(value, event) => {
                     this.setState({
                        content: value,
                        time: new Date().getTime()
                    })
                }}
                maxlength={100}
                rows={10}
                limitshow
                style={{
                    fontSize:"12pt"
                }}
            />

            <div style={{
                marginTop:"10pt",
                display:"flex",
                alignItems: "center" /* 在垂直方向上居中对齐 */


            }}>
                <div>背景颜色</div>
                {bgColors}

            </div>
            <Button  style={
                {
                    width: "90vw",
                    borderWidth:0,
                    fontSize: "12pt",
                    color: "#ffffff",
                    backgroundColor: "#C22627",
                    marginTop:"20pt"
                }} onClick={event => {
                    let content = this.state.content
                if(content === null || content === undefined){
                    content = ""
                }
                setLocalData(pid, "__bianqian_txt", content)
                setLocalData(pid, "__bianqian_time", new Date().getTime() + "")
                setLocalData(pid, "__bianqian_bg", this.state.bgColor)
                dashNeedRefresh()
                reloadWidget()
                closePage()
                toast("保存成功")

            }}>保存</Button>
            <div style={{
                marginTop:"20pt",
                fontSize:"8pt",
                color:"#8E8E93"
            }}>* 注：用户便签信息仅保存在手机本地</div>

        </div>)

    }

    update(data){
        this.setState(data)

    }

    componentDidMount() {


        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        // 从 URL 中获取特定参数的值
        const pid = params['__pid']
        let that = this
        changeTitleType(1)
        //上报
        eventReport("pv","biaoqian")
        getLocalDataAll(pid,function (mData) {

            log(`data == ${JSON.stringify(mData)}`)
            let data = mData['data']
            let __bianqian_txt = data["__bianqian_txt"]
            let __bianqian_time = data["__bianqian_time"]
            let __bianqian_bg = data["__bianqian_bg"]

            let time  = 0;
            if (__bianqian_time == null) {
                time = 0
            } else {
                time = parseInt(__bianqian_time)
            }
            if (__bianqian_txt === null || __bianqian_txt === undefined) {
                __bianqian_txt = ""
            }

            if (__bianqian_bg == null) {
                __bianqian_bg = "#181818"
            }
            that.update({
                content: __bianqian_txt,
                time: time,
                bgColor: __bianqian_bg
            })

        })


    }

    componentWillUnmount() {
    }


}