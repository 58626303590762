import React from 'react';

import "./appinfo.css"
import AppIcon from './img/icon300.png';
import AppScreenshot1 from './img/01.png';
import AppScreenshot2 from './img/02.png';
import AppScreenshot3 from './img/03.png';
// import AppScreenshot4 from './img/04.png';
import {Button} from "@nutui/nutui-react";


function isWeiXin() {
    let ua = window.navigator.userAgent.toLowerCase();
    const isWeixin = ua.indexOf('micromessenger') !== -1;
    if (isWeixin) {
        return true;
    } else {
        return false;
    }
}
function download() {
    const ua = window.navigator.userAgent.toLowerCase();
    const isWeixin = ua.indexOf('micromessenger') !== -1;
    if(isWeixin){
        return
    }

    let ran = navigator.userAgent
    let isAndroid = ran.indexOf('Android') > -1 || ran.indexOf('Linux') > -1
    let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

    if (isAndroid) {
        // 安卓代码块
        window.location.href = "https://mcoredata.oss-cn-beijing.aliyuncs.com/mofang.apk"
        return;
    }
    if (isIOS) {
        // ios代码块
        window.location.href = "https://apps.apple.com/cn/app/%E9%AD%94%E6%96%B9%E4%BF%A1%E6%81%AF/id6447499774"
        return;
    }
    window.location.href = "https://mcoredata.oss-cn-beijing.aliyuncs.com/mofang.apk"

}

function AppIntro() {

    let client = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test( navigator.userAgent)
    //pc 端
    if(!client){
        document.title = "Bimmer控制器"
       return ( <div className="app-intro" style={{color:"#ffffff",margin:"3vw"}}>
            <div className="app-icon-container">
                <img src="https://images.liqucn.com/mini/120x120/img/h03/h59/img_localize_94cccf32bf9aea6ae48456c5c78b6618_512x512_120x120.png" alt="App Icon" className="app-icon" style={{width:"100px",height:"100px"}} />
            </div>
            <h1 className="title" style={{fontSize:"24px"}}>Bimmer控制器</h1>
            <p></p>
            <p style={{fontSize:"16sp",alignContent:"center",display:"flex",justifyContent:"center"}}>一款超个性的汽车数据APP,为您提供数字化分析和展示。</p>

            <div className="app-screenshots" style={{marginTop:"80px"}}>
                <div className="app-screenshot-container">
                    <img src="https://is1-ssl.mzstatic.com/image/thumb/PurpleSource112/v4/d3/12/77/d3127705-ad69-e2ed-458d-2fcb4bed59fd/c7338deb-8ee3-4089-b22c-cd303439acc7__U5207_U7247_4.jpg/400x800bb.png" alt="App Screenshot 1" className="app-screenshot" />
                </div>
                <div className="app-screenshot-container">
                    <img src="https://is1-ssl.mzstatic.com/image/thumb/PurpleSource112/v4/72/6f/19/726f19c0-87aa-e67f-62ef-e86ec081f59d/fbae311b-1f53-4713-ae8a-f9f2cfea8073__U5207_U7247_2.jpg/400x800bb.png" alt="App Screenshot 2" className="app-screenshot" />
                </div>
                <div className="app-screenshot-container">
                    <img src="https://is1-ssl.mzstatic.com/image/thumb/PurpleSource112/v4/1f/0a/54/1f0a54ca-fef0-0d7d-6dd0-e0a203e7546c/1999a929-e975-4932-a16a-90efe8e21b27__U5207_U7247.jpg/400x800bb.png" alt="App Screenshot 3" className="app-screenshot" />
                </div>
            </div>

            <div style={{
                display:"flex",
                justifyContent:"center",
                fontSize: "14px",
                color: "#999999",
                width: "100vw",
                marginTop:"30px"
            }}>

                <a href="https://beian.miit.gov.cn/"
                   style={{
                       textAlign: "center",
                       left: "4vw",
                       color: "#999999",
                   }}>  沪ICP备2021019734号-1  </a>
            </div>
        </div>)

    }

    //移动端
    return (
        <div className="app-intro" style={{color:"#ffffff",margin:"3vw"}}>
            <div className="app-icon-container">
                <img src={AppIcon} alt="App Icon" className="app-icon" style={{width:"120px",height:"120px"}} />
            </div>
            <h1 className="title" style={{fontSize:"24px"}}>魔方信息</h1>
            <p></p>

            <div style={{fontSize:"16sp"
                ,display:"flex",
                margin:"20px",
                justifyContent:"center",}}>魔方信息是一款功能丰富的数据展示应用，旨在帮助用户更轻松地获取大量数据。它提供了数据面板和桌面小组件功能，让用户可以快速获得可视化数据，从而发现隐藏在数据中的有价值的信息和见解。</div>
            <div style={{
                display:"flex",
                justifyContent:"center",
            }}>
                <Button className="app-icon-container" style={{

                    fontSize:"14px",
                    width:"80vw",
                    maxWidth:"400px",
                    height:"50px",
                    marginTop:"4vw",
                    marginBottom:"4vw",
                }} onClick={()=>{
                    download()
                }}>{isWeiXin()?"请使用系统浏览器":"立即下载"}</Button>


            </div>

            <div className="app-screenshots" style={{
                display:"flex",
                justifyContent:"center",
            }}>
                <div className="app-screenshot-container">
                    <img src={AppScreenshot1} alt="App Screenshot 1" className="app-screenshot" />
                </div>
                <div className="app-screenshot-container">
                    <img src={AppScreenshot2} alt="App Screenshot 2" className="app-screenshot" />
                </div>
                <div className="app-screenshot-container">
                    <img src={AppScreenshot3} alt="App Screenshot 3" className="app-screenshot" />
                </div>
                {/*<div className="app-screenshot-container">*/}
                {/*    <img src={AppScreenshot4} alt="App Screenshot 4" className="app-screenshot" />*/}
                {/*</div>*/}
            </div>

            <div style={{
                display:"flex",
                justifyContent:"center",
                fontSize: "14px",
                color: "#999999",
                width: "100vw",
                marginTop:"30px"
            }}>

                <a href="https://beian.miit.gov.cn/"
                   style={{
                       textDecoration: "none",
                       textAlign: "center",
                       color: "#999999",
                   }}>  沪ICP备2021019734号-1  </a>
            </div>
        </div>
    );
}

export default AppIntro;