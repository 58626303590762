import React from "react";
import {log, setTitle, toast, userInfo} from "../../JSBridge";
import {Toast} from "@nutui/nutui-react";
import axios from "axios";
import {_baseApiUrl} from "../../Config";
import coupon_lj from "../vipcenter/img/coupon_lj.png";
import coupon_zk from "../vipcenter/img/coupon_zk.png";

export class Coupons extends React.Component {


    pageSize = 180
    pageIndex = 0;
    mUserInfo = null;

    constructor(props) {
        super(props);
        this.state = {
            mList: []
        }
        setTitle("我的优惠券")
        this.updateInfo();
    }

    getList(index, size) {
        let that = this
        Toast.show({
            content: '请稍后',
            icon: 'loading',
        })
        axios({
            url: _baseApiUrl + "/api/activity/couponList",
            method: "GET",
            headers: {
                token: this.mUserInfo.token
            },
            params: {
                vin: this.mUserInfo.vin,
                phone: this.mUserInfo.phone,
                pageNum: index,
                pageSize: size,
                orderByColumn:"createTime",
                isAsc:"DESC",
            },
            timeout: 5000
        }).then(function (response) {
            console.log(response);
            let data = response.data
            if (data.code !== 0) {
                toast(data.msg, "error")
            } else {

                log(data.rows.toString())
                that.pageIndex = index
                that.setState({
                    mList: data.rows

                })
            }

            Toast.clear()
        }).catch(function (error) {
            log(error.toString())
            Toast.clear()
            toast("请求失败请重试", "error")
        })

    }

    updateInfo() {
        let that = this;
        userInfo((data) => {
            if (data["code"] === 0 && data["data"] != null) {
                let mData = data.data
                let token = mData.token
                if (token != null && token !== "") {
                    this.mUserInfo = mData
                    this.getList(that.pageIndex +1, that.pageSize)
                } else {
                    Toast.show({
                        content: "登录信息有误，请重试",
                        icon: 'fail',
                    })
                }
            }

        })


    }

    render() {
        if( this.state.mList == null || this.state.mList.length===0){
            return (<div style={{background: "#191919",textAlign:"center",marginTop:"10vw", color:"#fff",
                fontSize:"3.33vw",}}>暂无数据</div>)
        }
        const getLabel = (coupon) => {
            switch (coupon.status) {
                case 1:
                    return "已使用"
                case -1:
                    return "已过期"
            }
            if(Date.parse(coupon.expTime)>Date.now()){
                return "去使用"
            }else{
                return "已过期"

            }

        }
        const content = this.state.mList.map((coupon) =>


            <div style={{
                fontSize: "3.5vw",
                background: "#272727",
                lineHeight: "14vw",
                height: "27.5vw",
                color: "#D9D9D9",
            }}>
                <div style={{
                    width: "92.31vw",
                    height: "24.17vw",
                    paddingTop: "3.33vw",
                }}>
                    <img style={{
                        position: "absolute",
                        marginLeft: "3vw",
                        height: "24.17vw",
                    }} src={coupon.type === "lijian" ? coupon_lj : coupon_zk}/>
                    <div style={{
                        position: "absolute",
                        width: "63.36vw",
                        height: "24.17vw",
                    }}
                    >
                        <div style={{
                            marginTop: "-2vw",
                            position: "relative",
                            width: "63.36vw",
                            height: "4.7vw",
                            fontSize: "3.33vw",
                            color: "#191919",
                            textAlign: "center",
                        }}>{coupon.desc}</div>
                        <div style={{
                            width: "63.36vw",
                            height: "8.33vw",
                            fontSize: "6.11vw",
                            color: "#fff",
                            marginTop: "4vw",
                            textAlign: "center",
                        }}>{coupon.type === "lijian" ? coupon.amount/100 : coupon.amount/10}<font style={{fontSize:"3.4vw",paddingLeft: "0.4vw"}}>
                            {coupon.type === "lijian" ? "元" :"折"}
                        </font></div>
                        <div style={{
                            color: "rgba(25,25,25,0.67)",
                            width: "63.36vw",
                            height: "4.72vw",
                            marginTop: "-0.4vw",
                            fontSize: "3.33vw",
                            textAlign: "center",
                        }}>{coupon.expTime}前有效
                        </div>
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            right: "0",
                            height: "24.17vw",
                            width: "34.98vw",
                            lineHeight:"24.17vw"
                        }}
                    >

                        <div style={{
                            margin: "10vw auto",
                            backgroundColor: getLabel(coupon)==="去使用"?"#191919":"#777777",
                            borderRadius: "4.17vw",
                            width: "18vw",
                            height: "5.56vw",
                            lineHeight: "5.56vw",
                            fontSize: "2.78vw",
                            color: "rgba(255,255,255,0.70)",
                            textAlign: "center",
                        }}>
                            {getLabel(coupon)}
                        </div>
                    </div>
                </div>
            </div>

        )
        return (<div style={{background: "#191919"}}>{content}</div>)

    }


}