import React from "react";

import banner from "./img/banner.jpg"
import "./Trace.css"

export class Trace extends React.Component {


    constructor(props) {
        super(props);
    }


    render() {

        let href = "https://beian.miit.gov.cn/";
        return (
            <div>
                <img src={banner} style={{
                    width: "100vw",
                    left: 0,
                    backgroundColor: "#191919",
                }}/>
                <div style={{
                    fontSize: "12sp",
                    color: "#ccc",
                    width: "100vw",
                    position: "absolute",
                    bottom: "4vw",
                    left: "4vw",
                }}>
                    上海沐小阳网络科技有限公司
                    <a href={href}
                       style={{
                           textDecoration: "none",
                           textAlign: "center",
                           left: "4vw",
                           color: "#ccc",

                       }}>  沪ICP备2021019734号-1  </a>
                </div>
            </div>
        )

    }


}