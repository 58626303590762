import React, {Component} from "react";

//theme
//core
import "../rubick.css"
import {Toast, Row, Col} from "@nutui/nutui-react";

import axios from "axios";
import {changeTitleType, eventReport, log, setTitle, toast} from "../../RubickJSBridge";
import {_baseApiUrl} from "../../Config";
import "../rubick.css"

import * as echarts from 'echarts';
import 'echarts/lib/chart/bar'; // 导入柱状图
import 'echarts/lib/component/tooltip'; // 导入提示框组件
import 'echarts/lib/component/title';
import {Link, NavLink} from "react-router-dom";


export class ShiwuhuangjinV2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        changeTitleType(1)
        eventReport("pv","shiwuhuangjin")


    }

    componentDidMount() {
        this.getList()
    }

    getList() {
        axios.post(_baseApiUrl + "api/coreData/data/post", {"provider": "shiwuhuangjin"})

            .then(response => {
                console.log("resp", response.data.data)
                let data = response.data.data
                this.setState({
                    mData: data
                })


            })
            .catch(function (error) {
                log(error.toString())
                toast("请求失败请重试", "error")
            })
    }

    calculateArrayStats(arr) {
        const max = Math.max(...arr);
        const min = Math.min(...arr);
        const sum = arr.reduce((acc, val) => acc + val, 0);
        const average = sum / arr.length;

        return {
            max,
            min,
            average,
        };
    }

    //头部数据

    header() {
        let list = []
        for (const key in this.state.mData) {
            if (this.state.mData.hasOwnProperty(key)) {
                const second = this.state.mData[key]
                second.market = key
                list.push(second)
            }
        }
        let huangjin = list.filter((val)=>{
            return val["黄金价格"] && val["黄金价格"].price !== undefined
        }).map((value, index, array)=>{
            return value["黄金价格"].price
        });
        let bojin = list.filter((val)=>{
            return val["铂金价格"]&& val["铂金价格"].price !== undefined
        }).map((value, index, array)=>{
            return value["铂金价格"].price
        });

        console.log("huangjin",huangjin)
        console.log("bojin",bojin)

        const  hj = this.calculateArrayStats(huangjin)
        const  bj = this.calculateArrayStats(bojin)



        return (
            <div>
                <div className={"r_title2"}>当前金价</div>
                <div className={"r_card"}
                     style={{backgroundColor: '#181818', height: "40vw", marginTop: "6vw", position: "relative"}}>
                    <Row>
                        <Col span="12">
                            <div style={{
                                height: "40vw",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                alignContent: "center"
                            }}>

                                <div>
                                    <div className="r_gray2">黄金均价</div>
                                    <div className="r_sub_count" style={{color: "#AC8E68",marginTop: "1vw"}}>{hj.average.toFixed(1)}</div>
                                    <div className="r_gray2" style={{marginTop: "4vw"}}>铂金均价</div>
                                    <div className="r_sub_count" style={{color: "#AC8E68",marginTop: "1vw"}}>{bj.average.toFixed(1)}</div>
                                </div>

                            </div>
                        </Col>
                        <Col span="12">

                            <div style={{
                                height: "40vw",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                alignContent: "center"
                            }}>

                                <div>
                                    <div className="r_gray2">最高黄金</div>
                                    <div className="r_sub_count" style={{color: "#FFFFFF", fontSize: "12pt",marginTop: "1vw"}}>{hj.max}</div>
                                    <div className="r_gray2" style={{marginTop: "4vw"}}>最高铂金</div>
                                    <div className="r_sub_count" style={{color: "#FFFFFF", fontSize: "12pt",marginTop: "1vw"}}>{bj.max}</div>
                                </div>
                                <div style={{marginLeft: "3vw"}}>
                                    <div className="r_gray2">最低黄金</div>
                                    <div className="r_sub_count" style={{color: "#FFFFFF", fontSize: "12pt",marginTop: "1vw"}}>{hj.min}</div>
                                    <div className="r_gray2" style={{marginTop: "4vw"}}>最低铂金</div>
                                    <div className="r_sub_count" style={{color: "#FFFFFF", fontSize: "12pt",marginTop: "1vw"}}>{bj.min}</div>
                                </div>

                            </div>
                        </Col>
                    </Row>

                </div>
            </div>
        )
    }


    bottom() {
        return (<div style={{height:"14vw",display:"flex",alignItems:"center",justifyContent:"center"}} className={"r_gray2"}>
                *数据来源互联网，以门店报价为准
        </div>)
    }

    item(data) {

        const inners = []
        for (const key in data) {
            if (key !== "market" && data.hasOwnProperty(key)) {
                const inner = data[key]
                if (!data.time) {
                    data.time = inner.time
                }
                inners.push(inner)
            }
        }
        const innerView = inners.map((inner, index) => {
            return (
                <Col span="8">
                    <div>
                        <div>
                            <div className={"r_gray2"} style={{textAlign: "center"}}>{inner.name}</div>
                            <div className={"r_sub_count"}
                                 style={{
                                     textAlign: "center",
                                     marginTop: "1vw",
                                     color: inner.increase !== 0 ? (inner.increase > 0 ? "#DC6B66" : "8DC0A9") : "#ffffff"
                                 }}>{inner.price}</div>
                        </div>
                    </div>
                </Col>)
        })

        return (
            <div
                style={{
                    backgroundColor: "#181818",
                    borderRadius: "10pt",
                    marginTop: "4vw",
                    padding: "10pt",
                }}>
                <div className={"r_gray2"} style={{height: "6vw"}}>{this.formatDate(data.time)}</div>
                <div>
                    <Row type="flex" justify="space-between" style={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                    }}>
                        <Col span="8">
                            <div>
                                <div style={{color: "#AC8E68",fontSize: "11pt"}}>
                                    {data.market}
                                </div>
                            </div>
                        </Col>
                        {innerView}
                    </Row>
                </div>
            </div>)


    }

    formatDate(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}/${month}/${day}`;
    }


    items() {
        const list = [];
        for (const key in this.state.mData) {
            if (this.state.mData.hasOwnProperty(key)) {
                const second = this.state.mData[key]
                second.market = key
                list.push(second)
            }
        }
        const items = list.map((data, index) => {
            return <div key={index}>{this.item(data)}</div>
        })
        return (
            <div>
                <div className={"r_subTitle"} style={{marginTop: "10vw"}}>金店报价</div>
                {items}
            </div>)
    }


    render() {

        if (this.state.mData === undefined) {
            return <></>
        }

        return (
            <div className={"r_page"}>
                {this.header()}
                {this.items()}
                {this.bottom()}

            </div>
        )
    }


}
