import React, {useState} from "react";
import {Calendar, ConfigProvider, Image} from '@nutui/nutui-react';
import '@nutui/nutui-react/dist/styles/theme-dark.scss';
import {changeTitleType, eventReport, openScheme} from "../RubickJSBridge";


export default function CalenderPage() {
    changeTitleType(1)
    eventReport("pv", "calender")

    return (

        <>
            <Calendar
                popup={false}
                title={"日历"}
                showSubTitle={false}
                poppable={false}
                startDate={null}
                endDate={null}
                isAutoBackFill
                showToday
                type="single"
                style={{backgroundColor: "#000000"}}
            />
        </>

    )

}