/*
注意：源生app需要配置jsbridge的环境，而在前端页面中需要下方封装代码，既可以达到调用app方法的功能和注册供app调用的方法
1、注册方法：注册后，供app调用，注册时，同名函数，下一个会覆盖上一个
2、调用函数，可以调用多次
3、使用时，只需要导入即可 如：import {setbridge, getbridge} from 'jsbridge'
4、使用方式：
调用app方法： getbridge(functionName,data,callback) 第一个参数是app的函数名字；
第2个参数是要传递给app方法的数据，数据类型不限（具体要看app接收什么类型的数据，如：json，string，number等）
第3个参数是一个函数，函数内隐含回调数据data，调用例子如下：

getbridge('closePage','关闭页面',(data) => {
  // 'app返回过来的数据：'+data
})
注册方法，供app使用：
setbridge(functionName,data,callback) 调用方式类似于上方说明
第一个参数：函数名字，可以自定义
第2个参数，app调用该方法是，需要给app传递的数据
第3个参数，回调函数，默认函数有一个数据data参数，得到的是app返回给前台js的数据

*/


function setupWebViewJavascriptBridge(callback) {
    if (/android/.test(navigator.userAgent.toLowerCase())) {
        if (window.WebViewJavascriptBridge) {
            callback(window.WebViewJavascriptBridge);
        } else {
            document.addEventListener(
                'WebViewJavascriptBridgeReady',
                function () {
                    callback(window.WebViewJavascriptBridge);
                },
                false
            );
        }
    } else if (/ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase())) {
        if (window.WebViewJavascriptBridge) {
            return callback(window.WebViewJavascriptBridge);
        }
        if (window.WVJBCallbacks) {
            return window.WVJBCallbacks.push(callback);
        }
        window.WVJBCallbacks = [callback];
        var WVJBIframe = document.createElement('iframe');
        WVJBIframe.style.display = 'none';
        WVJBIframe.src = 'https://__bridge_loaded__';
        document.documentElement.appendChild(WVJBIframe);
        setTimeout(function () {
            document.documentElement.removeChild(WVJBIframe);
        }, 100);
    }
}


let callbacks = {};
let uniqueId = 1;


/**
 * 不被export 都独立封装方法，防止不兼容的情况
 */
function doAction(funName, dataJson, callback) {
    if (window._js_bridge_callback == null) {
        window._js_bridge_callback = function (callbackId, data) {
            let callback = callbacks[callbackId];
            if (callback != null) {
                try{
                    let mDate = data == null ? null : data
                    log("onCallback:" + callbackId + " do callback");
                    callback(mDate)
                }catch (e) {
                    log("error:" + callbackId + " :"+e);

                }

                return true
            } else {
                log("onCallback:" + callbackId + "not found !!!");

                return false
            }
        }
    }

    let callbackId = null;
    if (callback != null) {
        callbackId = 'cb_' + (uniqueId++) + '_' + new Date().getTime();
        callbacks[callbackId] = callback;
    }

    setupWebViewJavascriptBridge(function (bridge) {
        bridge.doAction(funName, dataJson == null ? "" : JSON.stringify(dataJson), callbackId);
    });
}

/**
 * 将日志在客户端打印
 * @param msg
 */
export function log(msg) {
    console.log(msg)
    setupWebViewJavascriptBridge(function (bridge) {
        bridge.log(msg.toString());
    });
}

export function toast(msg, type) {
    doAction("showToast"
        , {
            "toast": msg,
            "type": type
        })

}

export function setTitle(title) {
    doAction("setTitle", {"title": title}, null)
}

export function closePage() {
    doAction("closePage")
}

/**
 * 获取当前支持的所有桥接action
 */
export function actions(callback) {
    doAction("actions", null, callback)

}

/**
 * 获取手机，应用的基础信息
 */
export function appInfo(callback) {
    doAction("appInfo", null, callback)
}

/**
 * 发起分享
 */
export function share(data, callback) {
    doAction("wxShare", data, callback)
}

/**
 * 发起支付操作
 */
export function wxPay(data, callback) {
    doAction("wxPay", data, callback)

}

/**
 * 应用市场评价
 */
export function marketFeedback(callback) {
    doAction("marketFeedback", null, callback)

}


/**
 * 播放广告
 */
export function adVideo(
    codeId,
    extra,
    scenes,
    callback) {
    doAction("adVideo", {
        codeId:codeId,
        extra:extra,
        scenes:scenes,
    }, callback)

}

/**
 * 应用权限
 */
export function permissionState(permissions, callback) {
    doAction("permissionState", {"permissions": permissions}, callback)


}

/**
 * 请求权限
 */
export function requestPermission(permissions, callback) {
    doAction("requestPermission", {"permissions": permissions}, callback)


}

/**
 * 打开页面
 */
export function openScheme(url) {
    doAction("openScheme", {"url": url}, null)

}

/**
 * 图片选着
 */
export function imageSelect(
    count,
    callback
) {
    doAction("imageSelect", {"count": count}, callback)

}


/**
 * 获取手机位置
 */
export function location(callback) {
    doAction("location", null, callback)

}


/**
 * titlebar 状态
 * type 0.普通，1.覆盖沉浸式 2.没有title 3.小程序
 * bgColor #ffffffff 包含alpha
 * statusBarColor 状态栏颜色
 * titleColor 标题颜色
 * iconColor 图标颜色
 * immersion 沉浸式
 * statusBarHeight（仅获取，无法设置）px
 *
 */
export function changeTitleBarState(config) {
    doAction("changeTitleBarState", config, null)
}

export function titleBarState(callback) {
    doAction("titleBarState", null, callback)

}

export function changeProgressType(config) {
    doAction("changeProgressType", config, null)
}


/**
 * 获取用户信息
 */
export function userInfo(callback) {
    doAction("userInfo", null, callback)


}


export function refreshToken(callback) {
    doAction("refreshToken", null, callback)


}


/**
 * 车辆信息列表
 */
export function vehicleList(startTime,endTime,num,callback) {
    doAction("vehicleList", {
        "startTime":startTime,
        "endTime":endTime,
        "num":num,
    }, callback)


}

/**
 * 当前车辆信息
 */
export function vehicleInfo(update,callback) {
    doAction("vehicleInfo", {"update":update}, callback)

}

/**
 * 拦截原生返回按钮
 * @param callback
 */
export function interceptBackPress(callback) {
    window._js_bridge_backPress = callback
    doAction("interceptBackPress")
}

