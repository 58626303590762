
function setupWebViewJavascriptBridge(callback) {
    if (/android/.test(navigator.userAgent.toLowerCase())) {
        if (window.WebViewJavascriptBridge) {
            callback(window.WebViewJavascriptBridge);
        } else {
            document.addEventListener(
                'WebViewJavascriptBridgeReady',
                function () {
                    callback(window.WebViewJavascriptBridge);
                },
                false
            );
        }
    } else if (/ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase())) {
        if (window.WKWebViewJavascriptBridge) { return callback(window.WKWebViewJavascriptBridge); }
        if (window.WKWVJBCallbacks) { return window.WKWVJBCallbacks.push(callback); }
        window.WKWVJBCallbacks = [callback];
        window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null)
    }
}

function setupWKWebViewJavascriptBridge(callback) {
    if (window.WKWebViewJavascriptBridge) { return callback(window.WKWebViewJavascriptBridge); }
    if (window.WKWVJBCallbacks) { return window.WKWVJBCallbacks.push(callback); }
    window.WKWVJBCallbacks = [callback];
    if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iOS_Native_InjectJavascript){
        window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null)
    }
}


let callbacks = {};
let uniqueId = 1;


/**
 * 不被export 都独立封装方法，防止不兼容的情况
 */
function doAction(funName, dataJson, callback) {

   if (/ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase())) {
       setupWKWebViewJavascriptBridge(function(bridge) {
           bridge.callHandler(funName, dataJson, callback)
       })
    }else if (/android/.test(navigator.userAgent.toLowerCase())){
       if (window._js_bridge_callback == null) {
           window._js_bridge_callback = function (callbackId, data) {
               let callback = callbacks[callbackId];
               if (callback != null) {
                   try{
                       let mDate = data == null ? null : data
                       // console.log("onCallback:" + callbackId + " do callback");
                       callback(mDate)
                   }catch (e) {
                       console.log("error:" + callbackId + " :"+e);
                   }
                   return true
               } else {
                   console.log("onCallback:" + callbackId + "not found !!!");
                   return false
               }
           }
       }

       let callbackId = null;
       if (callback != null) {
           callbackId = 'cb_' + (uniqueId++) + '_' + new Date().getTime();
           callbacks[callbackId] = callback;
       }

       setupWebViewJavascriptBridge(function (bridge) {
           bridge.doAction(funName, dataJson == null ? "" : JSON.stringify(dataJson), callbackId);
       });
   }
}



export function toast(msg, type) {
    doAction("showToast"
        , {
            "toast": msg,
            "type": type
        })

}

export function setTitle(title) {
    document.title = title;
    doAction("setTitle", {"title": title}, null)
}

export function closePage() {
    doAction("closePage")
}

/**
 * 获取当前支持的所有桥接action
 */
export function actions(callback) {
    doAction("actions", null, callback)

}

/**
 * 获取手机，应用的基础信息
 */
export function appInfo(callback) {
    doAction("appInfo", null, callback)
}

/**
 * 发起分享
 */
export function share(data, callback) {
    doAction("wxShare", data, callback)
}

/**
 * 应用市场评价
 */
export function marketFeedback(callback) {
    doAction("marketFeedback", null, callback)
}




/**
 * 应用权限
 */
export function permissionState(permissions, callback) {
    doAction("permissionState", {"permissions": permissions}, callback)


}

/**
 * 请求权限
 */
export function requestPermission(permissions, callback) {
    doAction("requestPermission", {"permissions": permissions}, callback)


}

/**
 * 打开页面
 */
export function openScheme(url) {
    doAction("openScheme", {"url": url}, null)

}

/**
 * event
 */
export function eventReport(event,p1,p2,p3,p4) {
    doAction("event", {
        "event": event,
        "p1": p1,
        "p2": p2,
        "p3": p3,
        "p4": p4,
    }, null)

}

/**
 * 图片选着
 */
export function imageSelect(
    count,
    callback
) {
    doAction("imageSelect", {"count": count}, callback)

}


/**
 * 获取手机位置
 */
export function location(callback) {
    doAction("location", null, callback)

}

//0 默认
//1 mini
//2 无title
export function changeTitleType(type){
    doAction("changeTitleType", {"type":type}, null)

}


export function log(msg) {
    doAction("log", {"msg":msg}, null)

}
/**
 * 获取用户信息
 */
export function userInfo(callback) {
    doAction("userInfo", null, callback)
}

/**
 * 设置本地数据
 */
export function setLocalData(pid,key,value) {
    doAction("setLocalData", {"id":pid,"key":key,"value":value+""}, null)
}

/**
 * 获取本地数据
 */
export function getLocalData(pid,key,callback) {
    doAction("getLocalData", {"id":pid,"key":key}, callback)
}

/**
 * 获取本地数据
 */
export function getLocalDataAll(pid,callback) {
    doAction("getLocalDataAll", {"id":pid}, callback)
}

/**
 * 刷新小组件
 */
export function reloadWidget() {
    doAction("reloadWidget", null, null)
}
/**
 * 通知dash 更新
 */
export function dashNeedRefresh() {
    doAction("dashNeedRefresh", null, null)
}




export function refreshToken(callback) {
    doAction("refreshToken", null, callback)
}

export function getBrick(id,callback) {
    doAction("getBrick", {"brickId":id}, callback)
}

document.title = "魔方信息"

// export function getPid(){
//     const location = Window.location
//     const queryParams = new URLSearchParams(location.search);
//
//     // 从 URL 中获取特定参数的值
//     const pid = queryParams.get('__pid');
//     // const sheet = queryParams.get('__sheet');
//     // const title = queryParams.get('__title');
//     // const titleType = queryParams.get('__titleType');
//     return pid
// }