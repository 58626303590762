import React from "react";
import './rubick.css'
import {Video} from "@nutui/nutui-react";
import {changeTitleType, eventReport, setTitle} from "../RubickJSBridge";

export class WidgetHelperForAndroid extends React.Component {

    constructor() {
        super();
        eventReport("pv","addWidget")
        changeTitleType(1)
        setTitle("如何添加小组件")
    }

    render() {

        const options = {
            controls: true,
            poster:
                'https://mcoredata.oss-cn-beijing.aliyuncs.com/assert/how_to_add_widget_poster.png',
        }
        return (<div className={"r_page"}>

            <div className={"r_title2"}>如何添加小组件</div>

            <div style={{
                marginTop: "10pt",
                marginBottom: "10pt",
                display: "flex",
                padding: "10pt",
                backgroundColor: "#181818",
                borderRadius: "10pt",
                color: "#999999",
                fontSize: "11pt"
            }}>
                数据模块添加到小组件可最快看到信息变化,让桌面价值最大,同时可美化桌面
            </div>

            <div className={"r_content"} style={{marginTop: "6pt"}}>
                1.在发现页添加「数据模块」到仪表盘
            </div>
            <div className={"r_content"} style={{marginTop: "6pt"}}>
                2.回到系统桌面长按空白处进入桌面编辑模式
            </div>
            <div className={"r_content"} style={{marginTop: "6pt"}}>
                3.点击左上角➕按钮唤起小组件面板
            </div>
            <div className={"r_content"} style={{marginTop: "6pt"}}>
                4.搜索「魔方信息」添加对应大小的组件
            </div>
            <div className={"r_content"} style={{marginTop: "6pt"}}>
                5.点按小组件（非编辑模式需长按）选取已经添加到仪表盘的数据模块
            </div>

            <div className={"r_content"} style={{marginTop: "20pt"}}>
                观看视频教程
            </div>
            <Video
            source={{
                src: 'https://mcoredata.oss-cn-beijing.aliyuncs.com/assert/how_to_add_widget.mp4',
                type: 'video/mp4',
            }}
            options={options}
            style={{
                marginTop:"10pt",
                height:"151.35",
                width:"70vw",
                marginLeft:"10vw",
                marginRight:"10vw",
            }}
            ></Video>
            <div style={{height:"30pt"}}>
            </div>

        </div>)
    }
}