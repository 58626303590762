import './../Bridge.css';
import './../RubickJSBridge'
import {
    toast,
    closePage,
    openScheme,
    setTitle,
    appInfo,
    marketFeedback,
    permissionState,
    requestPermission,
    imageSelect,
    userInfo,
    refreshToken,
    log,
    changeTitleType,
    getBrick,
    reloadWidget,
    dashNeedRefresh,
    setLocalData,
    getLocalData,
    getLocalDataAll,
    eventReport,
} from "../RubickJSBridge";
import {useState} from "react";
import {useLocation} from "react-router-dom";
import {_baseWebUrl} from "../Config";




function RubickBridge() {
    const [msg, setMsg] = useState("");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // 从 URL 中获取特定参数的值
    const pid = queryParams.get('__pid');
    const sheet = queryParams.get('__sheet');
    const title = queryParams.get('__title');
    const titleType = queryParams.get('__titleType');

    return (
        <div className="App" style={{height:"100vh",overflowY:"auto"}}>
            <div className="test-item">pid={pid},sheet={sheet}</div>
            <div className="test-item">title={title},titleType={titleType}</div>
            <div className="test-item" onClick={
                event => {
                    toast("Test Toast","error")
                    log("Test log")
                }

            }>Toast</div>
            <div className="test-item" onClick={
                event => {
                    changeTitleType(new Date().getTime()%3)
                }
            }>变成mini</div>

            <div className="test-item" onClick={
                event => {
                    openScheme("coredata:///core/feedback")
                }
            }>跳转Scheme</div>
            <div className="test-item" onClick={
                event => {
                    eventReport("H5.Click","0")
                }
            }>H5事件</div>
            <div className="test-item" id="result" onClick={event => setTitle("titleChange hh")}>改变Title</div>
            <div className="test-item" id="result" onClick={event => {
                appInfo(function (data) {
                    toast(JSON.stringify(data))
                })
            }}>获取APP信息</div>

            <div className="test-item" id="result" onClick={event => {
                marketFeedback(function (data) {
                })
            }}>应用市场评价</div>


            <div className="test-item" id="result" onClick={event => {
                permissionState(["READ_PHONE_STATE","READ_EXTERNAL_STORAGE","CAMERA"],function (data) {
                    toast(JSON.stringify(data))
                })
            }}>查看权限</div>
            <div className="test-item" id="result" onClick={event => {
                requestPermission(["READ_PHONE_STATE","READ_EXTERNAL_STORAGE","CAMERA"],function (data) {
                    toast(JSON.stringify(data))
                })
            }}>请求权限</div>
            <div className="test-item" id="result" onClick={event => {
                getBrick(14123,function (data) {
                    let base64Brick = data["data"]
                    document.getElementById("imgs").src = "data:image/jpeg;base64,"+base64Brick;

                })
            }}>Brick</div>
            <div className="test-item" id="result" onClick={event => {
                imageSelect(3,function (data) {
                    toast("选择数量："+data["images"].length)
                    if(data["images"] == null || data["images"].length === 0){
                        toast("请先选择图片")
                        return;
                    }
                    let imgBase64 = data["images"][0]
                    document.getElementById("imgs").src = "data:image/jpeg;base64,"+imgBase64;
                })
            }}>相片选择</div>

            <img id="imgs" width="50%"/>


            <div className="test-item" onClick={
                event => {
                    userInfo(function (data) {
                        toast(JSON.stringify(data))
                    })
                }
            }>
               用户信息
            </div>
            <div className="test-item" onClick={
                event => {
                    refreshToken(function (data) {
                        toast(JSON.stringify(data))
                    })
                }
            }>
                刷新用户token
            </div>

            <div className="test-item" onClick={
                event => {
                    reloadWidget()
                }
            }>
                刷新小组件
            </div>
            <div className="test-item" onClick={
                event => {
                    dashNeedRefresh()
                }
            }>
                更新Dash
            </div>

            <div className="test-item" onClick={
                event => {
                    setLocalData("13412_111_9999","updateTime",new Date().getTime()+"")
                }
            }>
                设置LocalData
            </div>

            <div className="test-item" onClick={
                event => {
                    getLocalData("13412_111_9999","updateTime",function (data) {
                        toast(data["data"])
                    })
                }
            }>
                获取LocalData
            </div>
            <div className="test-item" onClick={
                event => {
                    getLocalDataAll("13412_111_9999",function (data) {
                        toast(JSON.stringify(data))
                    })
                }
            }>
                获取LocalDataAll
            </div>


            <div className="test-item" onClick={
                event => {
                    closePage()
                }
            }>
                关闭页面
            </div>

            <div className="test-item" onClick={
                event => {
                    openScheme("coredata:///core/feedback")
                }
            }>跳转feedBack</div>

            <div className="test-item" onClick={
                event => {
                    openScheme("coredata:///core/vipStore")
                }
            }>跳转Vip</div>

            <div className="test-item" ><a href={_baseWebUrl+"/core_data/wangyiyun"}>music</a></div>

            <div className="test-item" ><a href={_baseWebUrl+"/calender?__titleType=1"}>日历</a></div>
            <div className="test-item" >
                <a href={"https://s.click.taobao.com/4OfNwFu"}>淘宝链接</a>
            </div>

        </div>
    );
}

export default RubickBridge;
