

import React, {useEffect, useState} from "react";
import axios from "axios";
import {_baseApiUrl} from "../../Config";
import {log, toast} from "../../JSBridge";
import "./price.css"
import {Tag, TrendArrow,Button} from "@nutui/nutui-react";
// import {Canvas,Candlestick} from '@antv/f-react';
import {   Axis, Chart, Tooltip } from '@antv/f2';
// import {KChart} from "../component/RBChart";


function  formatDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
}


export const PriceDetail = () => {

    const [count, setCount] = useState(30)


    //详情数据
    const [data, setData] = useState({});
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('mID');
    console.log(id)
    if (!id) {
        return (<div></div>)
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        //请求列表数据
        axios.get(_baseApiUrl + "api/coreData/price/detail?mID=" + encodeURIComponent(id))
            .then(function (response) {
                let mData = response.data.data
                setData(mData)
            }).catch(function (error) {
            log(error.toString())
            toast("请求失败请重试", "error")
        })
    }, [])
    if (!data?.list){
        return (<div></div>)
    }
    let acList = data?.list?.slice(0,count)?.reverse()

    const dates = acList?.map(value => {
        return formatDate(value.date)
    })
    console.log(dates)
    const values = acList?.map(value => {
      return [value.open,value.close,value.high,value.low]
    })
    console.log(values)

    const option = {
        xAxis: {
            scale: true,
            data: dates
        },
        yAxis: {
            scale: true,
            axisLine: { lineStyle: { color: '#8392A5' } },//y轴线的颜色
            splitLine: { show: false }//是否显示分隔线。默认数值轴显示，类目轴不显示。
        },
        series: [
            {
                type: 'candlestick',
                data: values,
                itemStyle: {// 对象样式
                    normal: {
                        color: '#FD1050',
                        color0: '#0CF49B',
                        borderColor: '#FD1050',
                        borderColor0: '#0CF49B'
                    }
                }
            }
        ]
    };

    let tag = <></>
    if(data?.info?.unit){
        tag = <Tag round color="#FA2400" style={{marginLeft:"4px"}} >{data?.info?.unit}</Tag>
    }

    const mm = [
        {
            time: '2017-10-24',
            // 格式为：[open, close, lowest, highest]
            value: [20, 34, 10, 38],
        },
        {
            time: '2017-10-25',
            value: [40, 35, 30, 50],
        },
        {
            time: '2017-10-26',
            value: [31, 38, 33, 44],
        },
        {
            time: '2017-10-27',
            value: [38, 15, 5, 42],
        },
    ];

    return (<div style={{margin: "5vw"}}>

        <div className="vname" style={{alignItems:"center", display: "inline-flex",alignContent:"center"}}>
            {data?.info?.name}
        </div>

        <div className="vclose" style={{marginTop:"10px"}}>{data?.info?.close} <TrendArrow rate={data?.info?.rate}/></div>
        <div>
            <Tag round type="primary" style={{marginLeft:"4px"}}>{data?.info?.title}</Tag>
            {tag}
        </div>
        <div>
            <Button shape="square" plain type="primary" icon="star-fill" onClick={e => {
                setCount(100)
            }}/>
        </div>

        {/*<KChart option={option}/>*/}
        {/*<div>*/}
        {/*    <Canvas pixelRatio={window.devicePixelRatio}>*/}
        {/*        <Chart data={mm}>*/}
        {/*            <Axis field="time" type="timeCat" />*/}
        {/*            <Axis field="value" formatter={(v) => Number(v).toFixed(0)} />*/}
        {/*            <Candlestick x="time" y="value" />*/}
        {/*            <Tooltip showCrosshairs={true} yPositionType="coord" crosshairsType="xy" showXTip showYTip />*/}
        {/*        </Chart>*/}
        {/*    </Canvas>*/}
        {/*</div>*/}
    </div>)


}
